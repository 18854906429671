import OfferAvailableTag from '../CouponsAndVouchers/OfferAvailableTag';
import styles from './index.module.scss';

const setBackgroundColor = (rating) => {
  if (rating < 2) return '#F54C52';
  if (rating < 3) return '#FF9F00';
  if (rating < 4) return '#66BB6A';
  if (rating < 5) return '#43A047';
  return '#2E7D32';
};

const RatingBox = ({
  isVisible,
  rating,
  ratingsCount = undefined,
  isNewStyledRatingBox,
  productInfo = null,
  isPdp = false,
  ratingCountColor = '#44454a',
}) => {
  return isVisible && rating ? (
    <div>
      <div className={styles.ratingBoxContainer}>
        <div
          className={`${styles.ratingBox} ${
            isNewStyledRatingBox ? styles.updatedRatingBox : ''
          }`}
          style={{ backgroundColor: setBackgroundColor(rating) }}
        >
          <p
            className={`mv0 pr4px ${
              isNewStyledRatingBox ? styles.updatedNoOfRating : ''
            }`}
          >
            {Number.isInteger(rating) ? rating : rating.toFixed(1)}
          </p>
          <img
            alt="Rating Star"
            src={
              isNewStyledRatingBox
                ? '/assets/images/green-star.svg'
                : '/assets/images/white-star.svg'
            }
            width="12px"
            height="11px"
          />
        </div>
        {ratingsCount && (
          <p
            className="mv0"
            style={{
              color: ratingCountColor,
            }}
          >
            <span>{ratingsCount}</span>
          </p>
        )}
        <OfferAvailableTag productInfo={productInfo} customClasses="tw-ml-[12px]" />
      </div>
    </div>
  ) : null;
};

export default RatingBox;
