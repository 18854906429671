import NextLink from 'next/link';
import { FunctionComponent, PropsWithChildren } from 'react';
import styles from './link.module.scss';

interface LinkProps {
  href: string;
  target?: string;
}
export const Link: FunctionComponent<PropsWithChildren<LinkProps>> = (props) => {
  return <NextLink href={props.href} target={props.target} passHref>
    <a className={styles.link}>
      {props.children}
    </a>
  </NextLink>;
}
