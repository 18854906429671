/**
 * check if stocks are low in quantity
 * @param {*} stockItem
 * @return {boolean} boolean
 */
export const checkForLimitedQuantity = (stockItem) => {
  if (
    !(
      stockItem?.managed_inventory &&
      stockItem?.available_quantity &&
      stockItem?.low_quantity
    )
  ) {
    return false;
  }

  return stockItem.available_quantity <= stockItem.low_quantity;
};
