import OfferIcon from 'src/assets/svgExports/OfferIcon';
import { useFeatureLocks } from 'src/hooks/useFeatureLocks';
import { IS_DESKTOP } from 'src/utils/checkClientDeviceType';

const OfferAvailableTag = ({ productInfo, customClasses = '' }) => {
  const isOfferAvailable = !!productInfo?.promo_offer?.is_any_promo_offer_available;
  const iconSize = IS_DESKTOP ? 14 : 10;

  const { couponsFeatureStatus } = useFeatureLocks();
  const isCouponsFeatureLocked = !!couponsFeatureStatus?.isLocked;

  return (
    !isCouponsFeatureLocked &&
    isOfferAvailable && (
      <span
        className={`tw-flex tw-items-center tw-gap-[4px] tw-text-[10px]/[20px] tw-font-medium md:tw-gap-[8px] md:tw-text-[13px]/[20px] ${customClasses}`}
      >
        <OfferIcon width={iconSize} height={iconSize} /> Offer available
      </span>
    )
  );
};

export default OfferAvailableTag;
