import styled from 'styled-components';
import { CartButton } from '../AtomicComponents';

export const CardContainer = styled.div`
  width: ${(props) => (!props.cardWidth ? '100%' : `${props.cardWidth}px`)};
  position: relative;
  box-sizing: border-box;
  border: ${(props) => !props.imageUrl && `0.4px solid #C7C7C7;`};
  padding: ${(props) => !props.imageUrl && `20px;`};
  border-radius: ${(props) => !props.imageUrl && `4px;`};
  cursor: pointer;
  ${(props) =>
    !!props.isAvailable || props.isB2bStore ? null : 'filter: grayscale(1);'};

  @media screen and (min-width: 767px) {
    max-width: ${(props) => (!props.cardWidth ? '200px' : `${props.cardWidth}px`)};
  }

  @media screen and (max-width: 766px) {
    ${(props) =>
      !props.imageUrl
        ? `grid-column: 1 / -1;`
        : `max-width: 200px;
           width: ${(props) => (!props.cardWidth ? 'calc((100vw - 60px) / 2)' : `${props.cardWidth}px`)};`}
  }
`;

export const CardBody = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  /* height: 100%; */
`;

export const CardImage = styled.section`
  width: ${(props) => props.imageWidth || '100%'};
  height: ${(props) => props.imageHeight || '100%'};
  position: relative;
  object-fit: cover;
  display: flex;
  aspect-ratio: 1;

  @media screen and (min-width: 767px) {
    ${(props) =>
      props.imageUrl
        ? `height: calc(200px/${
            props.product_aspect_ratio !== 0 ? props.product_aspect_ratio : 1
          });`
        : `height: 180px;`}; //As max-width of the box is set to 200px
  }

  @media screen and (max-width: 766px) {
    ${(props) =>
      props.imageUrl
        ? `height: calc(((100vw - 60px) / 2)/${
            props.product_aspect_ratio !== 0 ? props.product_aspect_ratio : 1
          });`
        : `height: auto`};
  }

  & > img {
    width: 100%;
    object-fit: cover;
    object-position: center center;
  }
`;

export const CardMain = styled.section`
  display: flex;
  width: 100%;
  box-sizing: border-box;
  padding: ${({ padding }) => padding};
  position: relative;

  ${(props) =>
    props.imageUrl &&
    `
    @media screen and (max-width: 400px) {
      flex-direction: column;
    }
  `}
`;

export const CardData = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-shrink: 1;
`;

export const CardInventory = styled.p`
  font-weight: 400;
  font-size: 12px;
  margin: 0 0 12px 0;
`;

export const CardTags = styled.div`
  display: flex;
  flex-flow: row wrap;
  padding: ${({ padding }) => padding};
`;

export const CardTag = styled.span`
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  padding: 2px 4px;
  margin-bottom: 4px;
  border-radius: 4px;
  color: #ffffff;
  font-size: 11px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  white-space: nowrap;
  margin-right: 10px;
`;

export const CardTitle = styled.h3`
  font-weight: 500;
  margin: 0.5rem 0;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 5px;
  line-height: 1.5;
  ${(props) => props.categoryCard && `text-align: center;`}
  font-size: 12px;
  word-break: break-word;
  color: ${(props) => props.fontColor};
`;

export const CardPrice = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

export const AmountDiscounted = styled.span`
  display: inline-block;
  color: ${(props) => props.fontColor};
  letter-spacing: 1.5px;
  font-size: 16px;
`;

export const AmountActual = styled.span`
  display: inline-block;
  text-decoration: line-through;
  color: ${(props) => props.fontColor};
  letter-spacing: 1.3px;
  margin-left: 10px;
  font-size: 13px;
  word-break: break-all;
`;

export const CardDiscount = styled.span`
  display: inline-block;
  position: absolute;
  background-color: #3ea200;
  color: #ffffff;
  font-size: 11px;
  text-transform: uppercase;
  z-index: 2;
  padding: 2px 3px;
  ${(props) => (props.imageUrl ? `bottom: 0px;` : `top: -25px;`)}
`;

export const ButtonContainer = styled.section`
  display: flex;
  flex: 0;
  flex-direction: column;

  @media screen and (min-width: 401px) {
    margin-left: 10px;
    margin-top: 0.5rem;
  }

  @media screen and (max-width: 400px) {
    margin-top: 10px;
  }
`;

export const Button = styled(CartButton)`
  @media screen and (min-width: 767px) {
    font-size: 22px;
  }

  @media screen and (max-width: 766px) {
    font-size: 18px;
    ${(props) => !props.hasVariant && `margin-bottom: 22px;`}
  }
`;

export const CardDescription = styled.p`
  margin: 0;
  font-weight: 400;
  letter-spacing: 0.26px;
  margin-top: 20px;
  color: ${(props) => props.fontColor};

  ${(props) =>
    props.isAvailable &&
    `
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;    
    `};

  @media screen and (min-width: 767px) {
    font-size: 14px;
  }

  @media screen and (max-width: 766px) {
    font-size: 11px;
  }
`;

export const CardDescriptionMock = styled.span`
  margin-top: 2px;
  display: inline-block;
`;
